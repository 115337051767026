.App {
  text-align: center;
}

:root{
  --amplify-primary-color: #3f51b5;
  --amplify-primary-tint: #0000FF;
  --amplify-primary-shade: #3f51b5;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.clearfix::after {
  display: block;
  content: "";
  clear: both;
}

.unread td {
  font-weight: bold;
}

.hand:hover {
  cursor: pointer;
}

.ptr--ptr {
  box-shadow: none !important;
}

.pt0 {
  padding-top: 0;
}
.pt1 {
  padding-top: 1rem;
}
.pt2 {
  padding-top: 2rem;
}
.pt3 {
  padding-top: 3rem;
}
.pt4 {
  padding-top: 4rem;
}
.pt5 {
  padding-top: 5rem;
}
.pb0 {
  padding-bottom: 0;
}
.pb1 {
  padding-bottom: 1rem;
}
.pb2 {
  padding-bottom: 2rem;
}
.pb3 {
  padding-bottom: 3rem;
}
.pb4 {
  padding-bottom: 4rem;
}
.pb5 {
  padding-bottom: 5rem;
}
.mt0 {
  margin-top: 0;
}
.mt1 {
  margin-top: 1rem;
}
.mt2 {
  margin-top: 2rem;
}
.mt3 {
  margin-top: 3rem;
}
.mt4 {
  margin-top: 4rem;
}
.mt5 {
  margin-top: 5rem;
}
.mb0 {
  margin-bottom: 0;
}
.mb1 {
  margin-bottom: 1rem;
}
.mb2 {
  margin-bottom: 2rem;
}
.mb3 {
  margin-bottom: 3rem;
}
.mb4 {
  margin-bottom: 4rem;
}
.mb5 {
  margin-bottom: 5rem;
}
.m0 {
  margin: 0;
}
.m1 {
  margin: 1rem;
}
.m2 {
  margin: 2rem;
}
.m3 {
  margin: 3rem;
}
.m4 {
  margin: 4rem;
}
.m5 {
  margin: 5rem;
}
.p0 {
  padding: 0;
}
.p1 {
  padding: 1rem;
}
.p2 {
  padding: 2rem;
}
.p3 {
  padding: 3rem;
}
.p4 {
  padding: 4rem;
}
.p5 {
  padding: 5rem;
}
